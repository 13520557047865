import React from 'react'
import styled from 'styled-components'
import { RichText } from '.'
import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'
import { useImageAnalytics } from '../../hooks/tracking/useImageAnalytics'
import { Image } from './Image'
import { ProductSlider } from './sliders'

const BlockLanding = ({ banner, title, titleList, subTitle, image, fontSize, white, description, products, shopenful, titleTagH1 }) => {

  return (
    <StyledBlock>
      {banner &&
        <BackgroundImage>
          <StyledImage fluid={banner.fluid} alt={title} />
        </BackgroundImage>
      }
      {image &&
        <Banner onClick={() => useImageAnalytics(image.fluid)}>
          <StyledImage fluid={image.fluid} alt={title} />
        </Banner>
      }
      {titleTagH1 ?
        title && typeof title === 'object' ?
          <TitleMain white={white} fontSize={fontSize}> {title.map((item, i) => <span key={i}> {item} <br></br> </span>)} </TitleMain> : <TitleMain white={white} fontSize={fontSize}> {title} </TitleMain>
        : title && typeof title === 'object' ?
          <Title white={white} fontSize={fontSize}> {title.map((item, i) => <span key={i}> {item} <br></br> </span>)} </Title> : <Title white={white} fontSize={fontSize}> {title} </Title>
      }


      {/* {titleList && typeof titleList === 'object' ?
      <Title white= {white} fontSize = {fontSize}> {titleList.map((item, i)=> <span key={i}> {item} <br></br> </span>) } </Title> : <Title> {title} </Title>} */}
      {subTitle &&
        <Subtitle> {subTitle} </Subtitle>
      }
      {
        description &&
        <Description>
          <RichText json={description} />
        </Description>
      }
      {
        products &&
        <ProductSlider
          shopenful={shopenful}
          title={products[0].title}
          button={products[0].button}
          products={products[0].products}
          landing={true}
          gifting={false}
        />
      }
    </StyledBlock>
  )
}
const StyledBlock = styled.div`
color: ${(props) => (props.background ? '#fff' : ' #000')};
display: block;
padding-bottom: 2px;
`
const BackgroundImage = styled.div`
position:absolute;
width:100%;
height: 100%;
z-index: -1;
`
const TitleMain = styled.h1`
font-family: ${fonts.splandor};
text-align: center;
margin: 0 auto;
padding: ${mobileVw(10)} ;
font-size: ${mobileVw(25)};
font-weight: 500;
color: ${(props) => (props.white ? colors.white : "")};
@media (min-width: ${desktopBreakpoint}) {
width: 90%;
font-size: ${(props) => (props.fontSize ? desktopVw(props.fontSize) : desktopVw(35))};
padding: ${desktopVw(10)} 0;
}
`
const Title = styled.h2`
font-family: ${fonts.splandor};
text-align: center;
margin: 0 auto;
padding: ${mobileVw(10)} ;
font-size: ${mobileVw(25)};
font-weight: 500;
color: ${(props) => (props.white ? colors.white : "")};
@media (min-width: ${desktopBreakpoint}) {
width: 90%;
font-size: ${(props) => (props.fontSize ? desktopVw(props.fontSize) : desktopVw(35))};
padding: ${desktopVw(10)} 0;
}
`
const Subtitle = styled.h2`
font-family: ${fonts.splandor};
text-align: center;
margin: 0 auto;
padding: ${mobileVw(4)} ;
font-size: ${mobileVw(25)};
color: ${(props) => (props.gold ? colors.mailleGold : colors.white)};
@media (min-width: ${desktopBreakpoint}) {
width: 60%;
font-size: ${desktopVw(25)};
padding: ${desktopVw(5)} 0;
}
`
const Banner = styled.div`
height: ${mobileVw(250)};
width: ${mobileVw(250)};
margin: 0 auto;
padding:  ${mobileVw(5)} 0;
margin-top: 2rem;
@media (min-width: ${desktopBreakpoint}) {
height: ${desktopVw(500)};
width: ${desktopVw(700)};
padding:  ${desktopVw(10)} 0;
}
`
const StyledImage = styled(Image)`
width:100%;
height:100%
`
const Description = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(18)};
  color: ${colors.black};
  margin: 0;
  padding: 0 ${mobileVw(10)};
  text-align: center;
  @media (min-width: ${desktopBreakpoint}) {
    width:75%;
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    margin: ${desktopVw(5)} auto;
    padding: 0 ${desktopVw(10)}
  }
`
export default BlockLanding