import React from 'react'
import BlockLanding from '../BlockLanding'
const ProductLanding = ({productSection, shopenful}) => {
  const {title, image, description, cards} = productSection
  return (
    <div>
      <BlockLanding title = {title} image = {image} description = {description} products={cards} shopenful={shopenful}/>
    </div>
  )
}

export default ProductLanding