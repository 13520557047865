import React, {useState} from 'react'
import styled from 'styled-components';
import { RecipeCard } from '.';
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  colors,
  fonts,
  letterSpacing,
} from '../../styles'
import { Button } from '../shared/Button'

const RecipeLanding = ({recipeSection}) => {
  const [showAll, setAll] = useState(false)
  let recipes = recipeSection.slides
  let title = recipeSection.titleList
  return (
    <RecipeLandingWrapper>
        {title && typeof title === 'object' ?
      <Title> {title.map((item, i)=> <span key={i}> {item} <br></br> </span>) } </Title> : <Title > {title} </Title>}
    <Container>
      {
        recipes.map((recipe, i)=>{
          return( 
              <RecipeCard
                  key={i}
                  slug={recipe.slug}
                  thumbnailImage={recipe.thumbnailImage}
                  title={recipe.title}
                  cta = 'Lire Plus'
                  recipeLanding = {true}
                />
          )
        })
      }
    </Container>
    <StyledWrapper onClick={()=> setAll(!showAll)}>
    <Button
      to={ 'https://maille.com/fr/cuisinez-chaque-saveur'}
      text={'Voir plus de recettes ​gourmet'}
      alt={'Voir plus de recettes ​gourmet'}
    />
    </StyledWrapper>
    </RecipeLandingWrapper>
  )
}
const RecipeLandingWrapper = styled.div`
display: flex;
flex-direction: column;
}
`

const Container = styled.div`
display: block;
width:85%;
margin: ${mobileVw(40)} auto ${desktopVw(20)};
@media (min-width: ${desktopBreakpoint}) {
  width:80%;
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${desktopVw(80)} ${desktopVw(30)};
  margin: ${desktopVw(50)} auto ${desktopVw(10)};
}
`
const Title = styled.h2`
font-family: ${fonts.splandor};
text-align: center;
margin: 0 auto;
padding: ${mobileVw(10)} ;
font-size: ${mobileVw(25)};
font-weight: 500;
color: ${(props) =>(props.white ? colors.white : "")};
@media (min-width: ${desktopBreakpoint}) {
width: 70%;
font-size: ${(props) =>(props.fontSize ? desktopVw(props.fontSize) : desktopVw(35))};
padding: ${desktopVw(10)} 0;
}
`
const StyledWrapper = styled.div`
     width: ${mobileVw(288)};
  // background-color: ${colors.mailleGold};
     padding: ${mobileVw(15)} 0 ${mobileVw(14)};
  // font-family: ${fonts.tivoli};
  // font-size: ${mobileVw(20)};
  // color: ${colors.white};
  // text-transform: uppercase;
  // letter-spacing: ${mobileVw(2)};
  // line-height: ${mobileVw(20)};
  // display: block;
     margin: 0 auto ${mobileVw(40)};
     z-index: 1;
  // display: ${(props) => (props.disappear ? `none` : `block`)};
     cursor: pointer;

   @media (min-width: ${desktopBreakpoint}) {
       width: ${desktopVw(350)};
       padding: ${desktopVw(22)} 0 ${desktopVw(16)};
  //   font-size: ${desktopVw(18)};
  //   letter-spacing: ${desktopVw(1.8)};
  //   line-height: ${desktopVw(16)};
       margin: 0 auto ;
   }
`
export default RecipeLanding