import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Button } from '../components/shared/Button'
import {
  FeaturedItemBlock,
  Space,
  SEO,
  RichText,
  LinkType,
} from '../components/shared'
import BlockLanding from '../components/shared/BlockLanding'
import Media from '../components/shared/Media'
import RecipeLanding from '../components/shared/RecipeLanding'
import { Image } from '../components/shared/Image'
import { useLang, useLocale } from '../hooks'
import { useVideoAnalytics } from '../hooks/tracking/useVideoAnalytics'
import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  colors,
  letterSpacing,
  fonts,
} from '../styles'
import { Video } from '../components/shared/Video'
import ProductLanding from '../components/shared/sliders/ProductLanding'
import LandingNewsletter from '../components/newsletter/LandingNewsletter'
import RecipeDownloadPopup from '../components/RecipeDownloadPopup/RecipeDownloadPopup'
const Landing = ({
  location,
  data: { contentfulPageLanding },
  pageContext: { shopenful },
}) => {
  const {
    id,
    name,
    slug,
    seoTitle,
    seoDescription,
    title,
    titleTagH1,
    titleList,
    subTitle,
    banner,
    video,
    captionFile,
    youTubeUrl,
    richTextDesktop,
    richTextMobile,
    productSection,
    recipeSection,
    recipeBookDownlaod,
    reviewProduct,
  } = contentfulPageLanding
  let [show, setShow] = useState(false)
  const locale = useLocale()

  const lang = useLang()
  const {} = recipeBookDownlaod
  return (
    <LandingPageWrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        location={location}
        pageType='Landing Page'
      />
      <BlockWrap>
        <BlockLanding
          banner={banner}
          title={titleList}
          subTitle={subTitle}
          fontSize={40}
          white={true}
          titleTagH1={titleTagH1}
        />

        <MediaWrapper>
          {/* <Media
            url={youTubeUrl}
          /> */}
          {locale == 'fr' && video && (
            <StyledVideo controls mp4={video.file.url} track = {captionFile} onClick={() => useVideoAnalytics(video)}/>
          )}
            <RecipeDownloadPopup data = {recipeBookDownlaod} reviewProduct = {reviewProduct}/>
          <Container>
            {locale == 'fr' && (
              <MediaDescription>
                <RichText json={richTextDesktop} />
              </MediaDescription>
            )}
          </Container>
        </MediaWrapper>
      </BlockWrap>
      <ContainerProduct>
        {productSection && (
          <ProductLanding
            productSection={productSection}
            shopenful={shopenful}
          />
        )}
      </ContainerProduct>
      <ContainerRecipe>
        {recipeSection && <RecipeLanding recipeSection={recipeSection} />}
      </ContainerRecipe>
    </LandingPageWrapper>
  )
}

const LandingPageWrapper = styled.section`
  overflow-x: hidden;
  margin-bottom: ${desktopVw(50)};
`
const Container = styled.div``
const MediaWrapper = styled.div``

const StyledVideo = styled(Video)`
width: ${mobileVw(250)};
height: ${mobileVw(264)};
margin: 0 auto;
padding: 1rem 0;
@media (min-width: ${desktopBreakpoint}) {
width: ${desktopVw(620)};
height: ${desktopVw(400)};
margin: 0 auto;
padding: 1rem 0;
`
const BlockWrap = styled.div`
  position: relative;
  padding-bottom: 2px;
`
const Banner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const ContainerProduct = styled.div``

const MediaDescription = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(18)};
  line-height: ${mobileVw(18)};
  color: black;
  margin: 0;
  text-align: center;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  padding: 0 ${mobileVw(10)};
  // & p:first-child{
  //   color: ${colors.white};
  //   font-size: ${mobileVw(25)};
  // }
  @media (min-width: ${desktopBreakpoint}) {
    width: 75%;
    font-weight: 500;
    font-size: ${desktopVw(22)};
    line-height: ${desktopVw(24)};
    margin: ${desktopVw(10)} auto;
    padding: 0 ${desktopVw(30)};
    // & p:first-child{
    //   font-size: ${desktopVw(25)};
    // }
  }
`
const ButtonWrapper = styled.div`
  padding: ${mobileVw(10)} ${mobileVw(16)} 0;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(10)} 0 ${desktopVw(0)};
  }
`
const StyledButton = styled(Button)`
  cursor: pointer;
  width: ${mobileVw(160)};
  border-radius: 10px;
  font-size: ${mobileVw(15)};
  margin: 0 auto ${mobileVw(25)};
  display: block;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(200)};
    font-size: ${desktopVw(15)};
    margin: 0 auto;
    padding: ${desktopVw(10)} ${desktopVw(15)};
  }
`
const ContainerRecipe = styled.div``

export const LandingQuery = graphql`
  query Landing($id: String!) {
    contentfulPageLanding(id: { eq: $id }) {
      id
      name
      slug
      seoTitle
      seoDescription {
        raw
      }
      title
      titleTagH1
      titleList
      banner {
        fluid(sizes: "(max-width: 1023px) 50vw, 50vw") {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      richTextDesktop {
        raw
      }
      richTextMobile {
        raw
      }
      video {
        file {
          url
        }
      }
      captionFile {
        file {
          fileName
          url
        }
      }
      landingPageCheckbox {
        raw
      }
      landingPagePopUpTnc {
        raw
      }
      cta {
        title
        name
      }
      ctaPopup {
        title
        pdfDownloads {
          title
          file {
            url
          }
        }
      }
      recipeBookDownlaod {
        name
        cta {
          title
        }
        image{
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
        }
        description {
          raw
        }
        thankyouMessage{
            raw
        }
        tncDescription {
          raw
        }
        checkboxText {
          raw
        }
        ctaPopUp {
          title
          pdfDownloads {
            title
            file {
              url
            }
          }
        }
      }
      reviewProduct {
        slug
      }
      productSection {
        name
        title
        description {
            raw
        }
        image {
          fluid(sizes: "(max-width: 1023px) 50vw, 50vw") {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        cards {
          ... on ContentfulComponentProductSlider {
            title
            button
            products {
              slug
              images {
                title
                fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              title

              thumbnailImage {
                fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailLabel
              shopifyProduct {
                id
                handle
              }
            }
          }
        }
      }
      recipeSection {
        titleList
        slides {
          ... on ContentfulPageRecipe {
            slug
            thumbnailImage {
              title
              fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            title
          }
        }
      }
    }
  }
`
export default Landing
